import { useMemo, useEffect } from "react";
import { items_state } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { useSpring, useTransition, animated } from "react-spring";
import { Line } from "../Line/Line";

export const ItemsHeader = ({ gloveComponent, componentItems }) => {
  const [itemsState] = useAtom(items_state);
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const activeItemName = componentItems[0]?.thumbnail && itemsState.activeObjs[gloveComponent._id].displayName;

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, []);

  return (
    <animated.div style={animationProps} className="flex-column mb-2">
      {gloveComponent.displayName && (
        <div className="text-center">
          <h6 className="mb-1">{gloveComponent.displayName.toUpperCase()}</h6>
          <Line color="black" height="1px" width="30px" boxShadow="none" />
        </div>
      )}
      {gloveComponent?.description && (
        <div className="description text-center">
          <p className="mt-2 mb-1">{gloveComponent.description}</p>
        </div>
      )}
      {activeItemName && (
        <div className="activeDisplayName text-center">
          <h5 className="mt-2">{activeItemName}</h5>
        </div>
      )}
    </animated.div>
  );
};
