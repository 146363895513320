/** Returns key/value as productCategory: index
 * Used throughout the app to prevent bugs if the category array were to rearrange
 */

import { useMemo } from "react";

export const useCategoryIndex = (productsState) => {
  const categoryIndexObj = useMemo(() => {
    if (!productsState.isPrimed) return null;
    return {
      foundationIndex: productsState.activeObj.category.findIndex((component) => component._id === "foundation"),
      modsIndex: productsState.activeObj.category.findIndex((component) => component._id === "mods"),
      colorsIndex: productsState.activeObj.category.findIndex((component) => component._id === "colors"),
    };
  }, [productsState.isPrimed]);

  return categoryIndexObj;
};
