import { Children } from "react";
import sift from "sift";
import { Accordion } from "../Accordion/Accordion";
import { CreateUIComponent } from "../../helpers/CreateUIComponent";
import { Line } from "../Line/Line";
import { useCategoryIndex } from "../../../hooks/useCategoryIndex";

const itemsCache = {};
export const ModsAccordion = ({ productsState, componentsStateArray, itemsStateArray }) => {
  const { modsIndex } = useCategoryIndex(productsState) || {};
  const modsComponents = componentsStateArray
    .filter(sift({ category: { $eq: "mods" }, $or: [{ excluded: { $exists: false } }, { excluded: false }] }))
    .sort((a, b) => {
      return productsState.activeObj.components.indexOf(a._id) - productsState.activeObj.components.indexOf(b._id);
    });

  const getItems = (component) => {
    // check if query is already cached
    if (itemsCache[component._id]) {
      return itemsCache[component._id];
    }

    // query is not cached, so fetch it, sort it, and add it to the cache
    itemsCache[component._id] = itemsStateArray
      .filter(sift({ _id: { $in: component.items }, $or: [{ outOfStock: { $exists: false } }, { outOfStock: false }] }))
      .sort((a, b) => {
        return component.items.indexOf(a._id) - component.items.indexOf(b._id);
      });
    return itemsCache[component._id];
  };

  return (
    <Accordion
      displayName={`${modsIndex + 1}. ${productsState.activeObj.category[modsIndex].displayName}`}
      id={productsState.activeObj.category[modsIndex]._id}
    >
      {Children.toArray(
        modsComponents.map((component, index) => (
          <>
            <CreateUIComponent gloveComponent={component} reactSchema={component.reactSchema} componentItems={getItems(component)} />
            {index !== modsComponents.length - 1 && <Line color="#AFAFAF" height="1px" width="85%" />}
          </>
        ))
      )}
    </Accordion>
  );
};
