import { Children, useState } from "react";
import sift from "sift";
import { Accordion } from "../Accordion/Accordion";
import { CreateUIComponent } from "../../helpers/CreateUIComponent";
import { useCategoryIndex } from "../../../hooks/useCategoryIndex";

const itemsCache = {};

export const ColorsAccordion = ({ productsState, componentsStateArray, itemsStateArray }) => {
  const { colorsIndex } = useCategoryIndex(productsState) || {};
  const [openNestedAccordion, setOpen] = useState(null);
  const colorsComponents = componentsStateArray
    .filter(sift({ category: { $eq: "colors" }, $or: [{ excluded: { $exists: false } }, { excluded: false }] }))
    .sort((a, b) => {
      return productsState.activeObj.components.indexOf(a._id) - productsState.activeObj.components.indexOf(b._id);
    });

  const getItems = (component) => {
    // check if query is already cached
    if (itemsCache[component._id]) {
      return itemsCache[component._id];
    }

    // query is not cached, so fetch it, sort it, and add it to the cache
    itemsCache[component._id] = itemsStateArray
      .filter(sift({ _id: { $in: component.items }, $or: [{ outOfStock: { $exists: false } }, { outOfStock: false }] }))
      .sort((a, b) => {
        return component.items.indexOf(a._id) - component.items.indexOf(b._id);
      });
    return itemsCache[component._id];
  };

  return (
    <Accordion
      displayName={`${colorsIndex + 1}. ${productsState.activeObj.category[colorsIndex].displayName}`}
      id={productsState.activeObj.category[colorsIndex]._id}
    >
      {Children.toArray(
        colorsComponents.map((component) => (
          // color components (i.e. accordions or leather_class)
          <CreateUIComponent
            gloveComponent={component}
            reactSchema={component.reactSchema}
            openObjState={{ state: openNestedAccordion, setState: setOpen }}
            componentItems={getItems(component)}
          />
        ))
      )}
    </Accordion>
  );
};
