import { memo, useMemo, Children } from "react";
import sift from "sift";
import { Accordion } from "../Accordion/Accordion";
import { CreateUIComponent } from "../../helpers/CreateUIComponent";
import { Line } from "../Line/Line";
import { useCategoryIndex } from "../../../hooks/useCategoryIndex";

const itemsCache = {};
// prevents component from rerendering unless product changes
const propsAreEqual = (prevProps, nextProps) => prevProps.productsState.activeId === nextProps.productsState.activeId;

export const FoundationAccordion = memo(({ productsState, componentsStateArray, itemsStateArray }) => {
  const { foundationIndex } = useCategoryIndex(productsState) || {};
  const foundationComponents = useMemo(
    () =>
      componentsStateArray.filter(sift({ category: { $eq: "foundation" } })).sort((a, b) => {
        return productsState.activeObj.components.indexOf(a._id) - productsState.activeObj.components.indexOf(b._id);
      }),
    []
  );

  const getItems = (component) => {
    // check if query is already cached
    if (itemsCache[component._id]) {
      return itemsCache[component._id];
    }

    // query is not cached, so fetch it, sort it, and add it to the cache
    itemsCache[component._id] = itemsStateArray
      .filter(sift({ _id: { $in: component.items }, $or: [{ outOfStock: { $exists: false } }, { outOfStock: false }] }))
      .sort((a, b) => {
        return component.items.indexOf(a._id) - component.items.indexOf(b._id);
      });
    return itemsCache[component._id];
  };

  return (
    <Accordion
      displayName={`${foundationIndex + 1}. ${productsState.activeObj.category[foundationIndex].displayName}`}
      id={productsState.activeObj.category[foundationIndex]._id}
    >
      {Children.toArray(
        foundationComponents.map((component, index) => (
          <>
            <CreateUIComponent gloveComponent={component} reactSchema={component.reactSchema} componentItems={getItems(component)} />
            {index !== foundationComponents.length - 1 && <Line color="#AFAFAF" height="1px" width="85%" />}
          </>
        ))
      )}
    </Accordion>
  );
}, propsAreEqual);
