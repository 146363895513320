import { useRef } from "react";
import { useSpring, animated } from "react-spring";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import "./styles.scss";

export const NestedAccordion = ({ openObjState, gloveComponent, children }) => {
  const [itemsState] = useAtom(items_state);
  const open = openObjState.state === gloveComponent._id;
  const accordionThumbnail = itemsState.activeObjs[gloveComponent._id].thumbnail[0];
  const ref = useRef();

  //toggle accordion function
  let toggleHandler = () => {
    // if already open then close it else open it
    openObjState.setState(open ? null : gloveComponent._id);

    scrollIntoView();

    !open && pushGTMAnalyticsEvent({ event: "Nested Accordion Opened - Bradley", category: gloveComponent.category, displayName: gloveComponent.displayName });
  };

  function scrollIntoView() {
    setTimeout(() => {
      let buildSection = document.getElementById("BuildSection");
      if (buildSection && ref.current.offsetTop) {
        buildSection.scrollTop = ref.current.offsetTop;
      }
    }, 50);
  }

  const openAnimation = useSpring({
    from: { opacity: 1 },
    to: { opacity: 1 },
    config: { duration: 300 },
  });

  //rotate animation
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#ffff",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#10d6f5" : "#fff",
    },
    config: { duration: "120" },
  });

  return (
    <>
      <animated.div ref={ref} className={`${open && "active"} nested accordion_item  mx-auto hover_effect my-2`} style={openAnimation} onClick={toggleHandler}>
        <div className="accordion_header d-flex align-items-center">
          {accordionThumbnail && <img className="me-2" src={accordionThumbnail} alt="Thumbnail" style={{ width: "12%" }} />}
          <h5 className="mb-0">{gloveComponent.displayName}</h5>
          <animated.i className="ms-auto" style={iconAnimation}>
            <img src="/images/iconExpandAccordion.png" alt="expand icon" />
          </animated.i>
        </div>
      </animated.div>
      {open && children}
    </>
  );
};
