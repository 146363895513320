import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { accordion_state } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import "./styles.scss";

export const BuildSection = ({ setShowCartTerms, children }) => {
  const [accordionState, setAccordionState] = useAtom(accordion_state);

  return (
    <Container fluid id="BuildSection">
      {children}
      {accordionState && (
        <div className="mt-3" style={{ marginBottom: "50vh" }}>
          <RectangleButton
            label="Add To Cart"
            onClickHandler={() => setShowCartTerms(true)}
            gaObj={{ event: "Add-to-Cart-Bradley", addToCartBtnLocation: "Build Section" }}
          />
        </div>
      )}
    </Container>
  );
};

BuildSection.propTypes = {
  children: PropTypes.node.isRequired,
  accordionState: PropTypes.string,
};
