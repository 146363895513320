import { HalfFloatType } from "three";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useThree } from "@react-three/fiber";
import { Environment, Plane } from "@react-three/drei";
import useShadowHelper from "../../hooks/useShadowHelper.js";

import { useLoader } from "@react-three/fiber";
import { useGLTFLoader } from "../dataManagers/AssetSystem3d";

export default function EnvironmentController() {
  const camera = useThree(({ camera }) => camera);
  const scene = useThree(({ scene }) => scene);
  const gl = useThree(({ gl }) => gl);
  const directionalLight_ref = useRef();
  // useShadowHelper(directionalLight_ref);

  const backgroundEl = useMemo(() => {
    return document.getElementById("builder-scene-canvas-container");
  }, []);

  const gltf = useLoader(useGLTFLoader, "/bradley-gloves-assets/environment/models/scenery-draco.glb");

  useEffect(() => {
    scene.add(camera); // so the camera is accessible in the three.js console
    backgroundEl.style.background = "#af5f4d";
    gltf.scene.children[0].layers.set(1); // set a layer so it can be hidden for the screenshot
    dispatchLoadedEvent();
  }, []);

  // signaling to the loading screen we're ready to go
  function dispatchLoadedEvent() {
    document.dispatchEvent(new CustomEvent("EnvironmentAssetsLoaded"));
  }

  return (
    <>
      {/* lighting */}
      <directionalLight
        ref={directionalLight_ref}
        args={["#fff", 1]}
        position={[0, 1.5, 0.2]}
        castShadow={true}
        shadowDarkness={1}
        shadow-radius={5}
        shadow-normalBias={0.001}
        shadow-mapSize-width={512}
        shadow-mapSize-height={512}
        shadow-camera-near={0.5}
        shadow-camera-far={1.6}
        shadow-camera-left={-0.35}
        shadow-camera-right={0.35}
        shadow-camera-top={0.4}
        shadow-camera-bottom={-0.35}
      />
      <ambientLight args={["#fff", 1]} />

      <primitive object={gltf.scene} rotation={[0, Math.PI, 0]} />

      <Environment
        background={false}
        files="/bradley-gloves-assets/environment/envMap/envMap.hdr"
        extensions={(loader) => {
          loader.setDataType(HalfFloatType);
        }}
        // preset={'forest'}
        // files={[`${envMapPath}/px.png`, `${envMapPath}/nx.png`, `${envMapPath}/py.png`, `${envMapPath}/ny.png`, `${envMapPath}/pz.png`, `${envMapPath}/nz.png`]}
      />

      <Plane args={[1, 1]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.02, 0]} receiveShadow>
        <shadowMaterial opacity={0.14} />
      </Plane>
    </>
  );
}
