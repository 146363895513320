import EmbedController from "../../embed/EmbedController";

export const getShoppingCartData = (productsState, componentsState, itemsState) => {
  let variantId = productsState?.activeObj?.shopify.variantIds[itemsState?.activeIds?.leather_class?._id] || null;
  let sku = productsState?.activeObj?.shopify.skus[itemsState?.activeIds?.leather_class?._id] || "";

  // create object that will be submitted to cart
  let cartSubmissionObj = {
    items: [
      {
        id: variantId,
        quantity: 1,
        properties: {
          SKU: sku,
          _customUrl: EmbedController?.parentUrl || window.location.href,
        },
      },
    ],
  };

  /**
   * add the 'properties' data to the obj so the full description of custom glove is present in the cart
   */

  componentsState.array.forEach((componentObj) => {
    if (!componentObj.excluded) {
      let activeItem = itemsState.activeObjs[componentObj._id];

      let key = componentObj.displayName;
      let value = activeItem.displayName;

      // add color to any color components
      if (componentObj.category === "colors") key = `${key} Color`;

      // make the value be the text_input if the customText_thumb item is active
      if (componentObj._id?.includes("thumb_customText")) {
        if (activeItem._id === "customText_thumb") {
          value = `"${activeItem.inputs.text_input}"`;
          // add additional font info
          cartSubmissionObj.items[0].properties[`${key} Font`] = activeItem.font_array[activeItem.inputs.active_font_index].displayName;
        }
      }

      // update the cartSubmissionObj with the custom image
      if (componentObj._id?.includes("thumb_mod") && activeItem._id === "customImage__thumb_mod") {
        if (activeItem.uploaded_logo_base64.length > 3) {
          // this is used as temporary storage for the custom image so we don't have to find these items again
          cartSubmissionObj.items[0].properties["Custom Image URL"] = activeItem.uploaded_logo_base64;
          cartSubmissionObj.tempThumbModId = componentObj._id;
          cartSubmissionObj.tempCustomUploadImageInputs = {
            _id: activeItem._id,
            inputs: { ...activeItem.inputs },
          };
        }
        // image has already been uploaded and is in the form of a src url
        else {
          cartSubmissionObj.items[0].properties["Custom Image URL"] = activeItem.uploaded_logo_src;
        }
      }

      // update key and value if a custom text embroidery item is active for the pinky mod
      if (componentObj._id?.includes("pinky_mod") && activeItem._id === "customText_pinky") {
        // add additional text and font info
        cartSubmissionObj.items[0].properties[`Pinky Mod ${value}`] = `"${activeItem.inputs.text_input}"`;
        cartSubmissionObj.items[0].properties[`Pinky Mod ${value} Font`] = activeItem.font_array[activeItem.inputs.active_font_index].displayName;
      }

      // update key and value if a custom number embroidery item is active for the thumb mod
      if (
        componentObj._id?.includes("thumb_mod") &&
        (activeItem._id === "customNumberEmbroidery__thumb_mod" || activeItem._id === "customNumberCircleCut__thumb_mod")
      ) {
        // add additional text and font info
        cartSubmissionObj.items[0].properties[`Thumb Mod ${value}`] = `"${activeItem.inputs.text_input}"`;
        cartSubmissionObj.items[0].properties[`Thumb Mod ${value} Font`] = activeItem.font_array[activeItem.inputs.active_font_index].displayName;
      }

      // update key and value if a custom number embroidery item is active for the web mod
      if (componentObj._id?.includes("webMod") && activeItem._id?.includes("customNumberEmbroidery__webMod")) {
        // add additional text and font info
        cartSubmissionObj.items[0].properties[`Web Mod ${value}`] = `"${activeItem.inputs.text_input}"`;
        cartSubmissionObj.items[0].properties[`Web Mod ${value} Font`] = activeItem.font_array[activeItem.inputs.active_font_index].displayName;
      }

      cartSubmissionObj.items[0].properties[key] = value;
    }
  });

  return [cartSubmissionObj];
};
