import { memo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMemo } from "react";
import { useTransition } from "react-spring";
import { ImageItem } from "./ImageItem";
import { TextItem } from "./TextItem";
import { ItemsHeader } from "./ItemsHeader";
import "./styles.scss";

export const ItemsSection = memo(({ gloveComponent, componentItems, children }) => {
  const animationOnItems = useTransition(componentItems, {
    trail: 40,
    initial: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    update: { enter: { opacity: 1, scale: 1 } },
    reset: true,
  });

  const dataToDisplayTransitionArray = useMemo(() => animationOnItems, [gloveComponent]);

  // button items shown in UI
  const items = dataToDisplayTransitionArray((style, item, key, index) => {
    return (
      <Col key={item._id} xs={gloveComponent.items.length > 2 ? "auto" : 6} className="my-1">
        {item.thumbnail ? (
          <ImageItem gloveComponent={gloveComponent} item={item} animation={style} />
        ) : (
          <TextItem gloveComponent={gloveComponent} item={item} animation={style} />
        )}
      </Col>
    );
  });

  return (
    <div className="ItemsSection my-3">
      <ItemsHeader gloveComponent={gloveComponent} componentItems={componentItems} />

      <Row className="items text-center justify-content-center">
        {items}
        {children}
      </Row>
    </div>
  );
});
