import { useMemo, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useForm } from "react-hook-form";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import "./styles.scss";

export const InputBox = ({ gloveComponent, additionalPrice = 0, ...props }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);

  const inputName = props.name;
  const { register, handleSubmit, setValue } = useForm({ mode: "onBlur" });
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const customTextItem = useMemo(() => itemsState.array.find((item) => item._id === props.itemId), [gloveComponent._id]);

  const maxCharLength = props.type === "number" ? 3 : 11;

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });

    // set inputbox to previous text
    setValue(inputName, itemsState.activeObjs[gloveComponent._id].text_input);

    // seed the activeItemIds with default inputs
    const copy = { ...itemsState.activeIds };
    if (copy[gloveComponent._id].inputs?.text_input === customTextItem?.inputs?.text_input) return;
    let newActiveInput = {};
    newActiveInput._id = customTextItem._id;
    newActiveInput.inputs = customTextItem.inputs;
    copy[gloveComponent._id] = newActiveInput;
    setItemsActiveIds(copy);
  }, [gloveComponent._id]);

  const onSubmitForm = (data) => {
    // abort if no text was entered or if the text is the same as the previous text
    if (data[inputName].length === 0 || customTextItem.inputs.text_input === data[inputName]) return;

    const copy = { ...itemsState.activeIds };
    let newActiveInput = {};

    newActiveInput._id = customTextItem._id;
    newActiveInput.inputs = customTextItem.inputs;

    newActiveInput.inputs.text_input = data[inputName];

    copy[gloveComponent._id] = newActiveInput;
    setItemsActiveIds(copy);
  };

  return (
    <animated.div className="InputBox" style={animationProps}>
      {/* onSubmit is used when enter key is pressed */}
      <form className="p-3" onSubmit={handleSubmit(onSubmitForm)} onBlur={handleSubmit(onSubmitForm)}>
        <div className="form-group">
          <div className="d-flex">
            <label htmlFor={inputName}> {props.label} </label>
            {additionalPrice != 0 && <h6 className="price ms-auto">{`$${additionalPrice}`}</h6>}
          </div>
          <div className="input-container">
            <input
              className="mb-3 form-control"
              name={inputName}
              type={props.type}
              maxLength={maxCharLength}
              onInput={(e) => {
                e.target.value = e?.target?.value?.slice(0, maxCharLength);
              }} // workaround for maxLength not working on 'number' inputs
              {...register(inputName, { required: false, maxLength: maxCharLength })}
            />
            <img src="/images/iconTextBox.svg" alt="Text Box" />
          </div>
        </div>

        <div className="d-flex justify-content-start" style={{ width: "100px" }}>
          <RectangleButton label="Apply Text" type="button" />
        </div>
      </form>
    </animated.div>
  );
};
