import { useState, useEffect, useMemo } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import EmbedController from "../../../../embed/EmbedController";
import AlertSlackOfError from "../../../../monitoring/AlertSlackOfError";
import SendMessageToSlack from "../../../../monitoring/SendMessageToSlack";
import { getFinalPrice } from "../../../modules/getFinalPrice";
import { getShoppingCartData } from "../../../modules/getShoppingCartData";
import { canvas_base64, session_id } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { Line } from "../Line/Line";
import { isMobile } from "react-device-detect";
import { uploadImageToDigitalOcean } from "../../../modules/uploadImageToDigitalOcean";
import { update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { delay } from "../../../modules/delay";
import "./styles.scss";

export const ShoppingCart = ({ cartTermsObj, productsState, componentsState, itemsState }) => {
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const [isCartSubmissionPending, setIsCartSubmissionPending] = useState(false);
  const [activeModalSection, setActiveModalSection] = useState("additionals");
  const { handleSubmit, register, watch, getValues } = useForm();
  const breakInServiceVariant = 39862673211471;

  const finalPrice = getFinalPrice(productsState, itemsState);

  const handleCloseModal = () => cartTermsObj.setState(false);

  // setup callback for response when parent cart is updated
  const [sessionId] = useAtom(session_id);
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      EmbedController.setCartResponseCallback((errorMsg, redirectUrl) => {
        if (errorMsg || !redirectUrl) {
          console.log(`ERROR adding to shopping cart`);
          setIsCartSubmissionPending(false);
          setModalHeader("");
          setTimeout(setModalHeader("ERROR: please try again"), 500);
          AlertSlackOfError("ShoppingCart.jsx cart response", `error: ${errorMsg}`, sessionId);
          alert("Error submitting to cart. Please try again.");
        } else if (redirectUrl) {
          handleCloseModal();
          setIsCartSubmissionPending(false);
          sendTransactionAnalytics();
          EmbedController.sendParentUrlChange(redirectUrl);
        }
      });
    }
  }, []);

  // if shopper has clicked "add to cart" and modal is showing for them to checkout
  useEffect(() => {
    if (cartTermsObj.state) {
      // signal to Scene.js to take a screenshot of caliper covers for the cart submission
      document.dispatchEvent(new CustomEvent("ScreenshotCanvasForCartImage"));
    }
  }, [cartTermsObj.state]);

  const [cartSubmissionObj] = useMemo(() => getShoppingCartData(productsState, componentsState, itemsState), [itemsState]);
  const [custom_image_base64] = useAtom(canvas_base64);

  async function submitToShoppingCart(formData) {
    setIsCartSubmissionPending(true);

    if (EmbedController.isEmbedded) {
      const isBreakInServicePurchased = formData.breakInService === "true";

      // update the custom image (screenshot of the product) to the cart obj so it's up-to-date
      let productScreenshotUrl = await uploadImageToDigitalOcean(custom_image_base64, "productScreenshots");
      if (productScreenshotUrl.slice(-11) === "default.jpg") {
        // upload failed, so retry
        await delay(2000);
        productScreenshotUrl = await uploadImageToDigitalOcean(custom_image_base64, "productScreenshots");
      }
      cartSubmissionObj.items[0].properties._customImage = productScreenshotUrl;

      // need to upload custom image to DO and update the cartSubmissionObj with the custom image upload url
      if (cartSubmissionObj.items[0].properties["Custom Image URL"] && cartSubmissionObj.tempCustomUploadImageInputs) {
        let DOUploadImageUrl = await uploadImageToDigitalOcean(cartSubmissionObj.items[0].properties["Custom Image URL"], "customImageUpload");
        if (DOUploadImageUrl.slice(-11) === "default.jpg") {
          // upload failed, so retry
          await delay(2000);
          DOUploadImageUrl = await uploadImageToDigitalOcean(cartSubmissionObj.items[0].properties["Custom Image URL"], "customImageUpload");
        }
        // update the custom image upload url in the cartSubmissionObj
        cartSubmissionObj.items[0].properties["Custom Image URL"] = DOUploadImageUrl;
        cartSubmissionObj.tempCustomUploadImageInputs.inputs.uploaded_logo_src = DOUploadImageUrl;
        setItemsActiveIds({
          ...itemsState.activeIds,
          [cartSubmissionObj.tempThumbModId]: cartSubmissionObj.tempCustomUploadImageInputs,
        });

        delete cartSubmissionObj.tempThumbModId;
        delete cartSubmissionObj.tempCustomUploadImageInputs;

        await delay(1000);
        cartSubmissionObj.items[0].properties._customUrl = EmbedController?.parentUrl;
      }

      // check for final notes
      cartSubmissionObj.items[0].properties["Final Notes"] = formData.finalNotes.length > 0 ? formData.finalNotes : "None";

      //check for break-in-service
      isBreakInServicePurchased &&
        cartSubmissionObj.items.push({
          id: breakInServiceVariant,
          quantity: 1,
        });

      console.log("cartSubmissionObj :>> ", cartSubmissionObj);

      EmbedController.sendAddToCart("/cart/add.js", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cartSubmissionObj),
      });

      // const activeLeatherClass = itemsState.activeObjs.leather_class;
      // SendMessageToSlack(
      //   `
      //   🎉 Product added to cart 🎉
      //   Price: ${isBreakInServicePurchased ? (parseInt(finalPrice) + 40).toFixed(2) : finalPrice} ${isBreakInServicePurchased ? `🥎` : ''}
      //   Glove: ${productsState.activeObj.displayName} | ${activeLeatherClass.displayName} | ${productsState.activeObj.shopify.variantIds[activeLeatherClass._id]}
      //   Customized URL: <${EmbedController?.parentUrl || window.location.href}|here >
      //   Session Id:: ${sessionId}
      //   isMobile: ${isMobile}
      //   `
      // )
    }
  }

  function sendTransactionAnalytics() {
    const formData = getValues();
    const isBreakInServicePurchased = formData.breakInService === "true";

    pushGTMAnalyticsEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: Math.floor(Math.random() * 10000), // Required - random unique id
            affiliation: "Online Store",
            revenue: isBreakInServicePurchased ? (parseInt(finalPrice) + 40).toFixed(2) : finalPrice,
            tax: 0,
            shipping: 0,
          },
          products: [
            {
              name: productsState.activeObj.displayName, // Name or ID is required.
              id: productsState.activeId,
              price: productsState.activeObj.shopify.prices[itemsState.activeIds.leather_class._id],
              quantity: 1,
            },
            ...(isBreakInServicePurchased
              ? [
                  {
                    name: "Break In Service", // Name or ID is required.
                    id: breakInServiceVariant,
                    price: 40,
                    quantity: 1,
                  },
                ]
              : []),
          ],
        },
      },
    });
  }

  // contains break-in service and final notes
  const AdditionalSection = () => {
    return (
      <>
        <Modal.Header>
          <Modal.Title className="mx-auto text-uppercase">ADDITIONAL</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <img className="mx-auto" src="/images/breakInService.png" />
          <h4 className="font-weight-bold mt-2">Add Break-In Service - $50</h4>
          <form>
            <div className="d-flex justify-content-around my-3">
              <label htmlFor="breakInService">
                <input
                  {...register("breakInService", { required: true })}
                  type="radio"
                  name="breakInService"
                  value={true}
                  className="form-check-input mx-1"
                  id="breakInService"
                />
                Yes, Please
              </label>
              <label htmlFor="noBreakInService">
                <input
                  defaultChecked
                  {...register("breakInService", { required: true })}
                  type="radio"
                  name="breakInService"
                  value={false}
                  className="form-check-input mx-1"
                  id="noBreakInService"
                />
                No, Thank You
              </label>
            </div>

            <Line color="#AFAFAF" height="1px" width="85%" />

            <div className="d-flex flex-column">
              <h4 className="my-3">Final Notes</h4>
              <textarea defaultValue={null} className="mx-auto" cols={35} rows={4} {...register("finalNotes")} placeholder="Anything you'd like to mention?" />
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button className="mx-2 px-2 submitBtn" onClick={() => setActiveModalSection("terms-conditions")}>
                Continue
              </button>
              <button type="button" className="mx-2 px-2" style={{ backgroundColor: "grey" }} onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </>
    );
  };

  const [modalHeader, setModalHeader] = useState("TERMS AND CONDITIONS");
  useEffect(() => {
    if (isCartSubmissionPending) setModalHeader("Enjoy Your Purchase!");
  }, [isCartSubmissionPending]);

  const TermsConditionsSection = () => {
    return (
      <>
        <Modal.Header>
          <Modal.Title className="mx-auto text-uppercase">{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
          <p>Current turnaround time for custom orders</p>
          <h3 className="font-weight-bold">10 to 12 weeks</h3>
          <p>
            by clicking below you agree to our{" "}
            <a href="https://www.bradleybaseballgloves.com/pages/terms-of-service" target="_blank">
              terms of service
            </a>{" "}
            regarding all custom products. ALL SALES OF CUSTOM PRODUCTS ARE FINAL, except in cases of egregious factory error.
          </p>
          <form onSubmit={handleSubmit(submitToShoppingCart)}>
            <label className="fw-bold">
              <input className="mx-1" name="acceptTerms" type="checkbox" {...register("acceptTerms", { required: true })} />I understand that my order cannot be
              canceled, exchanged, or returned.
            </label>
            <br />
            <div className="d-flex justify-content-center mt-2">
              <button className="mx-2 px-2 submitBtn" disabled={!watch("acceptTerms") || isCartSubmissionPending} type="submit">
                I Agree
                {isCartSubmissionPending && (
                  <Spinner
                    animation="border"
                    style={{
                      width: "inherit",
                      height: "inherit",
                      verticalAlign: "0",
                      marginLeft: "5px",
                    }}
                  />
                )}
              </button>
              <button type="button" className="mx-2 px-2" style={{ backgroundColor: "grey" }} onClick={() => setActiveModalSection("additionals")}>
                Go Back
              </button>
            </div>
          </form>
        </Modal.Body>
      </>
    );
  };

  const cartModal = () => (
    <Modal show={cartTermsObj.state} size="md" style={{ zIndex: 1202 }} backdrop="static" onHide={handleCloseModal}>
      {activeModalSection === "additionals" ? <AdditionalSection /> : <TermsConditionsSection />}
    </Modal>
  );

  return <div className="ShoppingCart">{cartModal()}</div>;
};
