import AlertSlackOfError from "../../monitoring/AlertSlackOfError";
const AWS = require("aws-sdk");
const { v4: uuidv4 } = require("uuid");

// TODO: change this per project
const clientFolder = "bradley-baseball";

const spacesEndpoint = new AWS.Endpoint("sfo3.digitaloceanspaces.com");

const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: process.env.REACT_APP_SPACES_KEY,
  secretAccessKey: process.env.REACT_APP_SPACES_SECRET,
  maxRetries: 10,
});

const s3Params = {
  Bucket: "tastetest3d",
  ACL: "public-read",
};

export async function uploadImageToDigitalOcean(imageBase64, path) {
  const savePaths = {
    productScreenshots: `${clientFolder}/product-screenshots/${uuidv4()}.png`,
    customImageUpload: `${clientFolder}/custom-image-upload/${uuidv4()}.png`,
  };

  const imageBuffer = Buffer.from(imageBase64.replace(/^data:image\/\w+;base64,/, ""), "base64");

  const params = {
    ...s3Params,
    Key: savePaths[path],
    Body: imageBuffer,
    ContentEncoding: "base64",
    ContentType: "image/png",
  };

  try {
    const uploadResult = await s3.upload(params).promise();
    console.log(`uploadResult.Location ${uploadResult.Location}`);
    return uploadResult.Location;
  } catch (error) {
    AlertSlackOfError(`uploadImageToDigitalOcean: ${path}`, error, null);
    console.log(error);
    return `https://tastetest3d.sfo3.digitaloceanspaces.com/${clientFolder}/product-screenshots/default.jpg`;
  }
}
