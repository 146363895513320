import { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import refreshExperience from "../../modules/refreshExperience";
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";
import { session_id } from "../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";

import "./styles.scss";

// used to keep track of errors thrown in a session and not send duplicates to slack
let errorCache = {};

export function HandleGeneralErrors({ children }) {
  const [sessionId] = useAtom(session_id);

  function logErrorToSlack(error, info) {
    if (!errorCache[error]) AlertSlackOfError("HandleGeneralErrors", `ERROR: ${error} __|__ STACK: ${info?.componentStack}`, sessionId);
    errorCache[error] = true;
    if (`${error}`?.includes("Could not load")) refreshExperience("HandleGeneralErrors", sessionId, true, true); // auto reload (same URL) the experience if the shopper experiences the "could not load model" error
  }

  return (
    <ErrorBoundary
      FallbackComponent={HandleGeneralErrorsFallbackScreen}
      onError={logErrorToSlack}
      onReset={() => refreshExperience("HandleGeneralErrors", sessionId)}
    >
      {children}
    </ErrorBoundary>
  );
}

function HandleGeneralErrorsFallbackScreen({ error, resetErrorBoundary }) {
  const [sessionId] = useAtom(session_id);

  const variableMessage = useMemo(() => {
    if (error?.message?.includes("Error creating WebGL context")) {
      return (
        <p>
          This customizer uses graphics technology that requires WebGL to work.<br></br>
          Please try again on a different device or close other tabs/apps and reload. <br></br>
        </p>
      );
    } else if (error?.message?.includes("getShaderPrecisionFormat")) {
      return (
        <p>
          We apologize, but this customizer utilizes graphics technology and is not compatible your device + browser combination. Please try again on an
          alternative web browser or laptop for the best experience.<br></br>
        </p>
      );
    } else {
      return (
        <p>
          Our team was just notified and will look into the issue.<br></br>
          In the meantime, please reload and try again. <br></br>
        </p>
      );
    }
  }, [error]);

  return (
    <div className="sceneLoadingScreen errorScreen">
      <div className="contentContainer d-flex flex-column justify-content-center align-items-center">
        <h2>We're Sorry, An Error Has Occurred</h2>

        {variableMessage}

        <p>
          If the issue persists, please send us an email at <a href="mailto:support@tastetest3d.com">support@tastetest3d.com</a> <br></br>
          with the following code attached: <span>{sessionId}</span>
        </p>

        <button onClick={resetErrorBoundary}>RELOAD</button>
      </div>
    </div>
  );
}
