import { useSpring, animated } from "react-spring";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { accordion_state } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import "./styles.scss";

export const Accordion = ({ id, displayName, children }) => {
  const [accordionState, setAccordionState] = useAtom(accordion_state);
  const open = id === accordionState;

  //toggle accordion function
  let toggleHandler = (e) => {
    setAccordionState(open ? null : id);

    // scroll to top
    const buildSectionEl = document.getElementById("BuildSection");
    if (buildSectionEl) buildSectionEl.scrollTop = 0;

    !open && pushGTMAnalyticsEvent({ event: "Accordion Opened - Bradley", accordionLabel: id });
  };

  const openAnimation = useSpring({
    from: { opacity: 1 },
    to: { opacity: 1 },
    config: { duration: 300 },
  });

  //rotate animation
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#ffff",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#10d6f5" : "#fff",
    },
    config: { duration: "120" },
  });

  return (
    <>
      <animated.div className={`${open && "active"} accordion_item  mx-auto hover_effect my-2`} style={openAnimation} onClick={toggleHandler}>
        <div className="accordion_header d-flex align-items-center">
          <h5 className="mb-0">{displayName}</h5>
          <animated.i className="ms-auto" style={iconAnimation}>
            <img src="/images/iconExpandAccordion.png" alt="expand icon" />
          </animated.i>
        </div>
      </animated.div>
      {open && children}
    </>
  );
};
