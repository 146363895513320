import AlertSlackOfError from "../monitoring/AlertSlackOfError";
import { decompressHash, compressHash } from "../react/components/dataManagers/useHash";

/**
 * This script handles logic for when the experience is embedded in an iframe in a client's site
 *
 * It is imported into our Root.js file and is subsequently used around the codebase (mainly in top-level data components)
 *
 * Messages will be passed between the parent site and embed iframe so our configurator can communicate with the client's site
 */

/**
 * variables
 */

let windowId = null;
let resizeTimer = null;
let hashChangeTimer = null;
let logoUploadTimer = null;
const hashChangeCallbacks = [];
const logoUploadCallbacks = { front: null, rear: null };
let cartResponseCallback = null;
let localStorage = null;

/**
 *
 * EmbedController object that is exported to the rest of the codebase
 *
 * Used to send messages to the parent site
 *
 */

const EmbedController = {
  // used around the codebase to know if experience is embedded or not
  isEmbedded: false,

  // full parent URL for saving a config
  parentUrl: "",

  // activates the embed
  activate() {
    this.isEmbedded = true;
    setupMessageListeners();
  },

  /**
   *
   *
   * sending messages to parent
   *
   */

  // send request for hash change to the parent site
  sendHashChange(hash) {
    hash = compressHash(hash); // CUSTOM CODE: only necessary if hashes are being compressed
    sendMessageToParent({
      method: "hashchange",
      hash,
    });
  },

  // send request to change full URL of parent site (redirect)
  sendParentUrlChange(url) {
    sendMessageToParent({
      method: "parentUrlChange",
      url,
    });
  },

  // send request to reload the parent site
  sendParentReloadRequest() {
    sendMessageToParent({
      method: "parentReloadRequest",
    });
  },

  // send request to fully refresh the parent site
  sendParentRefreshRequest() {
    sendMessageToParent({
      method: "parentRefreshRequest",
    });
  },

  // send data to upload a custom logo
  sendUploadLogo(requestUrl, options, filename, isFront) {
    sendMessageToParent({
      method: "uploadLogo",
      requestUrl,
      options,
      filename,
      isFront,
    });
  },

  // send data to add to client site's cart
  sendAddToCart(requestUrl, options) {
    sendMessageToParent({
      method: "addToCart",
      requestUrl,
      options,
    });
  },

  // send localStorage data to client site
  sendLocalStorage(action, storageDetails) {
    sendMessageToParent({
      method: "localStorage",
      action, // set, get, remove
      storageDetails, // keyName and time to live data
    });
  },

  // send google analytics data to client site
  sendGTMAnalyticsEvent(data) {
    sendMessageToParent({
      method: "GTMAnalyticsEvent",
      dataLayerObj: data,
    });
  },

  /**
   *
   * callback's for responses from parent
   *
   */

  // listener that fires a cb when a hashchange on the parent occurs
  // we just store cb's in here and then process then in the event listener above
  // Called in UrlDataController.js
  setHashChangeCallback(callback) {
    hashChangeCallbacks.push(callback);
  },

  // upload logo responses
  setUploadLogoCallback_front(callback) {
    logoUploadCallbacks.front = callback;
  },
  setUploadLogoCallback_rear(callback) {
    logoUploadCallbacks.rear = callback;
  },

  // when product is added to cart
  setCartResponseCallback(callback) {
    cartResponseCallback = callback;
  },

  // local storage response
  setLocalStorageCallback(callback) {
    localStorage = callback;
  },
};

// if embedded in an iframe
// and not already activated
// activate
if (window.self !== window.top && !EmbedController.isEmbedded) EmbedController.activate();

function sendMessageToParent(message) {
  if (!EmbedController.isEmbedded) return;
  window.parent.postMessage(message, "*");
}

/**
 *
 * handle messages received from parent
 *
 */

function setupMessageListeners() {
  window.addEventListener("message", function (e) {
    const method = e.data.method;
    if (!method) {
      return;
    }

    if (messageHandlers[method]) {
      messageHandlers[method](e);
    }
  });
}

const messageHandlers = {
  register: handleRegisterMessageFromParent,

  hashchange: handleHashChangeMessage,

  "request-size": handleRequestSizeMessage,

  logoUploaded: handleUploadLogoResponse,

  cartResponse: handleCartResponseMessage,

  localStorage: handleLocalStorageMessage,
};

function updateParentUrlField(newUrl) {
  EmbedController.parentUrl = newUrl;
}

async function handleRegisterMessageFromParent(e) {
  console.log("e.data.initialParentHash", e.data.initialParentHash);
  // CUSTOM CODE: decompress hash
  e.data.initialParentHash = decompressHash(e.data.initialParentHash);
  // window._tt will hold data from client site
  window._tt = e.data;
  windowId = e.data.windowId;

  // initialize parentUrl
  updateParentUrlField(e.data.initialParentUrl);

  // tell's Root.js to inject react code to start experience
  document.dispatchEvent(new CustomEvent("InitEmbed"));

  sendHeight();
  setTimeout(() => {
    sendHeight();
  }, 100);
  setTimeout(() => {
    sendHeight();
  }, 250);
}

// listener is set by onHashChange() above, which is called in UrlDataController.js
function handleHashChangeMessage(e) {
  updateParentUrlField(e.data.fullUrl);
  let hash = e.data.hash;
  clearTimeout(hashChangeTimer);
  // CUSTOM CODE: decompress hash
  hash = decompressHash(hash);
  hashChangeTimer = setTimeout(() => {
    hashChangeCallbacks.forEach((listener) => {
      listener(hash);
    });
  }, 100);
}

function handleUploadLogoResponse(e) {
  const { errorMsg, savedUrl, isFront } = e.data;
  if (errorMsg) {
    AlertSlackOfError("handleUploadLogoResponse in EmbedController", errorMsg);
    alert("Error with custom logo upload. Please refresh and try again.");
    return;
  }
  clearTimeout(logoUploadTimer);
  logoUploadTimer = setTimeout(() => {
    if (isFront) logoUploadCallbacks.front(savedUrl);
    else logoUploadCallbacks.rear(savedUrl);
  }, 100);
}

function handleRequestSizeMessage(e) {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function () {
    sendHeight();
  }, 100);
}

function handleCartResponseMessage(e) {
  const { errorMsg, redirectUrl } = e.data;
  cartResponseCallback(errorMsg, redirectUrl);
}

// send's height to client site
function sendHeight() {
  if (windowId) {
    const body = document.body;
    const height = Math.max(body.scrollHeight, body.offsetHeight);
    sendMessageToParent({ method: "resize", windowId: windowId, height: height });
  }
}

function handleLocalStorageMessage(e) {
  const { foundItem } = e.data;

  if (foundItem) localStorage(foundItem);
}

export default EmbedController;
