import { useCallback } from "react";
import { animated } from "react-spring";
import { Boop } from "../Animations/Boop";
import Tooltip from "react-bootstrap/Tooltip";
import { isMobile } from "react-device-detect";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import _ from "lodash";

export const ImageItem = ({ gloveComponent, item, animation }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const isActive = itemsState.activeIds[gloveComponent._id]._id === item._id ? "activeImage" : null;

  const handleClick = () => {
    // abort if active item was reclicked
    if (itemsState.activeIds[gloveComponent._id]._id === item._id) return;

    let copy = { ...itemsState.activeIds };
    copy[gloveComponent._id] = { _id: item._id };

    if (!_.isEmpty(item.inputs)) copy[gloveComponent._id].inputs = item.inputs;

    setItemsActiveIds(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - Bradley",
      category: gloveComponent.category,
      itemSection: gloveComponent.displayName,
      item: item.displayName,
    });
  };

  const tooltip = (props) => (
    // transitionDelay prevents jitter on safari
    <Tooltip {...props} style={{ zIndex: 3000, transitionDelay: "200ms", ...props.style }} id={`tooltip-details-${item._id}`}>
      {item.displayName}
    </Tooltip>
  );

  return (
    <animated.div style={animation}>
      <Boop boopType="scale" scale={1.02} timing={200}>
        {isActive && <img className="checkMark" alt="check mark" src="/images/checkmark.svg" />}
        <OverlayTrigger key={item._id} placement="bottom" overlay={isMobile ? <></> : tooltip}>
          <div className={isActive}>
            <ImgWithFallback
              className={`imageItems ${gloveComponent.category}`}
              src={item.thumbnail[0]}
              fallback={item.thumbnail[1] || item.thumbnail[0]}
              onClick={handleClick}
            />
          </div>
        </OverlayTrigger>
      </Boop>
    </animated.div>
  );
};
