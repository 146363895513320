import { useState, useEffect, useRef } from "react";
import { FileDrop } from "react-file-drop";
import { Line } from "../Line/Line";
import { useSpring, animated } from "react-spring";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import _ from "lodash";
import "./styles.scss";

export const FileDragDrop = ({ label, gloveComponent, additionalPrice = 0 }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const [previewImg, setPreviewImg] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const fileInputRef = useRef(null);
  const customImageItem = itemsState.array.find((item) => item._id === "customImage__thumb_mod");

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });

    // set preview image
    if (!_.isEmpty(customImageItem?.uploaded_logo_base64)) {
      setPreviewImg(customImageItem.uploaded_logo_base64);
    } else if (!_.isEmpty(customImageItem?.uploaded_logo_src)) {
      setPreviewImg(customImageItem.uploaded_logo_src);
    }
  }, []);

  const handleOnUpload = (files, event) => {
    // file came from browse button
    if (event.currentTarget.className.includes("browseLocalFiles")) {
      files = event.target.files;
    }

    const acceptedFileTypes = ["png", "jpeg"];
    if (!acceptedFileTypes.some((el) => files[0].type.includes(el))) {
      setErrorMessage("*Try uploading a PNG or JPEG*");
      return;
    } else {
      setErrorMessage(null);
    }

    let fileReader = new FileReader();
    // convert to base64
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = () => {
      updateItems(fileReader.result);
      setPreviewImg(fileReader.result);
    };
  };

  const onTargetClick = () => {
    // mimic a click
    fileInputRef.current.click();
  };

  const updateItems = (processedImgFile) => {
    const copy = { ...itemsState.activeIds };
    let newActiveInput = {};

    newActiveInput._id = customImageItem._id;
    newActiveInput.inputs = customImageItem.inputs || {};
    newActiveInput.inputs.uploaded_logo_src = `temp-${Math.random()}`;

    customImageItem.uploaded_logo_base64 = processedImgFile;

    copy[gloveComponent._id] = newActiveInput;
    setItemsActiveIds(copy);
  };

  return (
    <animated.div style={animationProps}>
      <div className="d-flex flex-column mb-2">
        <div className="d-flex position-relative">
          {label && <h6 className="mx-auto my-2">{label}</h6>}
          {additionalPrice !== 0 && <h6 className="price position-absolute" style={{ right: 0 }}>{`$75`}</h6>}
        </div>
        {label && <Line color="black" height="1px" width="30px" boxShadow="none" />}
      </div>
      <div className="FileDragDrop p-3 mb-4">
        <div className="dropContainer">
          <FileDrop onDrop={(files, event) => handleOnUpload(files, event)} onTargetClick={() => onTargetClick()}>
            <div className="d-flex flex-column">
              <img
                className="mt-3 mx-auto"
                src={previewImg ? previewImg : "/images/dragDropPlaceHolderImg.svg"}
                alt="Place Holder"
                style={{ userSelect: "none", pointerEvents: "none" }}
              />
              {errorMessage && <p className="errorMessage mx-auto mt-4">{errorMessage} </p>}
              <p className="my-3 mx-auto">
                Drop your image here or <span className="browseFilesButton">browse</span>
              </p>
              <input
                onChange={(event) => handleOnUpload(null, event)}
                onClick={(event) => {
                  event.target.value = null;
                }}
                ref={fileInputRef}
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
                type="file"
                className="hidden browseLocalFiles"
              />
              <p className="mx-auto">Supports JPG and PNG</p>
            </div>
          </FileDrop>
        </div>
      </div>
    </animated.div>
  );
};
