import { useState } from "react";
import { BuildSection } from "../BuildSection/BuildSection";
import { SceneLoadingScreen } from "../SceneLoadingScreen/SceneLoadingScreen";
import { SceneLoadingScreenTransparent } from "../SceneLoadingScreenTransparent/SceneLoadingScreenTransparent";
import { HUD } from "../HUD/HUD";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { useAtom } from "jotai";
import { products_state, components_state, update_components_activeId, items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { HeadersBuildSection } from "../BuildSection/HeadersBuildSection";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { WidgetsContainer } from "../Widgets/WidgetsContainer";
import { FoundationAccordion } from "../AccordionsContainer/FoundationAccordion";
import { ModsAccordion } from "../AccordionsContainer/ModsAccordion";
import { ColorsAccordion } from "../AccordionsContainer/ColorsAccordion";
import { ShoppingCart } from "../ShoppingCart/ShoppingCart";
import { getFinalPrice } from "../../../modules/getFinalPrice";
import ControlsIndicator from "../ControlsIndicator/ControlsIndicator";

export const BuilderUI = () => {
  // products
  const [productsState] = useAtom(products_state);

  // components
  const [componentsState] = useAtom(components_state);
  // const [, setComponentsActiveId] = useAtom(update_components_activeId);
  // const [, setComponentsState] = useAtom(components_state);

  // items
  const [itemsState] = useAtom(items_state);
  // const [, setItemsActiveIds] = useAtom(update_items_activeIds);

  const [showCartTerms, setShowCartTerms] = useState(false);

  const finalPrice = getFinalPrice(productsState, itemsState);

  return (
    <>
      <Container fluid className="BuilderUI p-0">
        {/* UI section on left (desktop) or bottom (mobile) of screen */}
        {productsState.isPrimed && componentsState.isPrimed && itemsState.isPrimed && (
          <BuildSection setShowCartTerms={() => setShowCartTerms(true)}>
            <HeadersBuildSection
              headerLabel={productsState.activeObj.displayName}
              subheaderLabel={`$${finalPrice}`}
              headerLineImgSrc="/images/bradleyHeaderLine.svg"
            />

            <FoundationAccordion productsState={productsState} componentsStateArray={componentsState.array} itemsStateArray={itemsState.array} />
            <ModsAccordion productsState={productsState} componentsStateArray={componentsState.array} itemsStateArray={itemsState.array} />
            <ColorsAccordion productsState={productsState} componentsStateArray={componentsState.array} itemsStateArray={itemsState.array} />

            {showCartTerms && (
              <ShoppingCart
                cartTermsObj={{ state: showCartTerms, setState: setShowCartTerms }}
                productsState={productsState}
                componentsState={componentsState}
                itemsState={itemsState}
              />
            )}
          </BuildSection>
        )}

        {/* UI section overlaying the 3D scene */}
        {/* Uses .shared-scene-sizing to mimic the scene's canvas. */}
        <HUD>
          {/* loading screen used for the initial load - it covers the scene's canvas */}
          <SceneLoadingScreen />
          {/* transparent loader for when assets are loading dynamically but we don't need to cover up whole screen */}
          <SceneLoadingScreenTransparent ignoreLoadingManager={true} />

          {productsState.isPrimed && componentsState.isPrimed && itemsState.isPrimed && (
            <>
              {/* popup to inform the user to grab the scene to move camera or platform */}
              <ControlsIndicator />

              <WidgetsContainer>
                <div className="float-left d-inline-flex">
                  <RectangleButton
                    label="Add To Cart"
                    iconSrc="/images/iconShoppingCart.svg"
                    onClickHandler={() => setShowCartTerms(true)}
                    gaObj={{ event: "Add-to-Cart-Bradley", addToCartBtnLocation: "Scene" }}
                  />
                </div>
                <div className="float-right d-inline-flex me-2"></div>
              </WidgetsContainer>
            </>
          )}
        </HUD>
      </Container>
    </>
  );
};
