import React, { useState, useEffect, useRef } from "react";
import { useActiveItem } from "../../../modules/useActiveItem";
import { is_experience_loaded_and_revealed } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";

import "./styles.scss";

export const SceneLoadingScreen = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [itemLoadedCount, setItemLoadedCount] = useState(0);
  const [areEnvironmentAssetsLoaded, setAreEnvironmentAssetsLoaded] = useState(false);
  const [, setIsExperienceLoadedAndRevealed] = useAtom(is_experience_loaded_and_revealed);

  // we'll setup event listeners to know when all item's 3D assets have been loaded
  // then we'll make the loading screen invisible
  useEffect(() => {
    document.addEventListener("ItemAssetsLoaded", handleItemAssetsLoaded);
    document.addEventListener("EnvironmentAssetsLoaded", handleEnvironmentAssetsLoaded);
    return () => {
      document.removeEventListener("ItemAssetsLoaded", handleItemAssetsLoaded);
      document.removeEventListener("EnvironmentAssetsLoaded", handleEnvironmentAssetsLoaded);
    };
  }, []);

  function handleItemAssetsLoaded(e) {
    setItemLoadedCount((itemLoadedCount) => itemLoadedCount + 1);
  }

  function handleEnvironmentAssetsLoaded() {
    setAreEnvironmentAssetsLoaded(true);
  }

  // setup min timer for loading screen
  const [isMinTimerDone, _setIsMinTimerDone] = useState(true);
  const isMinTimerDoneRef = useRef(true);
  const setIsMinTimerDone = (newState) => {
    isMinTimerDoneRef.current = newState;
    _setIsMinTimerDone(newState);
  };

  // checking if everything's loaded
  useEffect(() => {
    if (itemLoadedCount >= 1 && areEnvironmentAssetsLoaded && isMinTimerDoneRef.current) {
      setTimeout(() => {
        hideLoadingScreen();
      }, 250);
    }
  }, [itemLoadedCount, areEnvironmentAssetsLoaded, isMinTimerDone]);

  const [elOpacity, setElOpacity] = useState(1);
  function hideLoadingScreen() {
    if (isVisible) document.dispatchEvent(new CustomEvent("SceneIsBeingRevealed"));
    setIsExperienceLoadedAndRevealed(true);
    // TODO: implement fade animation
    setIsVisible(false);
  }

  // Whenever a new env is loaded, we want to show the loading screen
  const activeEnvItem = useActiveItem("environment_state");
  useEffect(() => {
    setIsVisible(true);
    setAreEnvironmentAssetsLoaded(false);
    // Show loading screen for a minimum of 1000ms so animations and such can occur properly
    if (isMinTimerDoneRef.current) {
      setIsMinTimerDone(false);
      setTimeout(() => {
        setIsMinTimerDone(true);
      }, 1000);
    }
  }, [activeEnvItem?._id]);

  function handleImageLoad(event) {
    event.target.classList.add("loaded");
  }

  return (
    <>
      {isVisible && (
        <div className="sceneLoadingScreen d-flex justify-content-center align-items-center" style={{ opacity: elOpacity }}>
          <div className="pl">
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="content-container d-flex flex-column justify-content-center align-items-center">
              <div className="loadingLogoContainer">
                <img className="loadingLogo" src="/images/loading-logo.png" onLoad={handleImageLoad} />
              </div>
              <div className="pl__text">Loading…</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
