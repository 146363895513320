import { Float, PresentationControls } from "@react-three/drei";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import sift from "sift";
import * as THREE from "three";
// import { traverse } from 'object-traversal';
// import sift from 'sift';
import { ResizeObserver } from "@juggle/resize-observer";
// import useMediaQuery from '@mui/material/useMediaQuery';
import EnvironmentController from "./EnvironmentController";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import AssetSystem3d, { useAssetLoader, useGLTFLoader } from "../dataManagers/AssetSystem3d";
// import { useActiveItem } from '../../modules/useActiveItem';
import { useAtom } from "jotai";
import { isMobile } from "react-device-detect";
import {
  canvas_base64,
  components_state,
  // loading_state,
  // update_loading_count,
  // is_experience_loaded_and_revealed,
  customEmbroideryAtomsObj,
  customImageAtom,
  glove_rotation_override,
  glove_scale,
  items_state,
  lacing_map,
  products_state,
} from "../dataManagers/GlobalDataManagers";
import { CanvasCompositor } from "./CanvasCompositor/CanvasCompositor";
// import { set } from 'lodash';
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";
import PanCameraFromCursorControls from "./PanCameraFromCursorControls";
import { ScrollToScaleControls } from "./scrollToScaleControls";

// var TWEEN = require('tween.js');

export default function Scene() {
  const [productsState] = useAtom(products_state);
  const [componentsState] = useAtom(components_state);
  const [itemsState] = useAtom(items_state);

  // const lookAtTarget = useMemo(() => new THREE.Vector3(0, 0.3, 0), [])

  return (
    <>
      {/* handles compositing the canvas(es) for custom embroideries */}
      {itemsState.isPrimed &&
        productsState.activeObj?.customEmbroideryInfo?.map((embroideryInfoObj, i) => {
          let applicableComponentId;
          if (embroideryInfoObj.applicableComponentId === "webMod")
            applicableComponentId = componentsState.array.filter(
              sift({
                _id: function (value) {
                  return value?.includes("webMod");
                },
                $or: [{ excluded: { $exists: false } }, { excluded: false }],
              })
            )[0]?._id;
          else applicableComponentId = embroideryInfoObj.applicableComponentId;

          return <CanvasCompositor key={i} applicableComponentId={applicableComponentId} textureAtom={customEmbroideryAtomsObj[embroideryInfoObj.atomName]} />;
        })}
      {/* handles compositing the canvas for custom image uploads */}
      {itemsState.isPrimed && (
        <CanvasCompositor
          key="customImage"
          applicableComponentId={"customImage"} // not real, just used as a a flag in CanvasCompositor
          textureAtom={customImageAtom}
        />
      )}

      {/* canvas that will hold a screenshot of the main three.js canvas for the shopping cart */}
      <canvas
        id="screenshot_canvas"
        // style={{position: "absolute", top: "0", right: "0", zIndex: "10000", border: "5px solid red"}}
        style={{ display: "none" }}
        width={1024}
        height={1024}
      ></canvas>

      {/* three scene's canvas */}
      <Canvas
        id="builder-scene-canvas-container"
        className="shared-scene-sizing builder-scene-canvas-container"
        camera={{
          position: [0.9, 1.3, -1.5],
          rotation: [0, 0, 0],
          fov: isMobile ? 25 : 25,
          near: 0.1,
          far: 100,
        }}
        gl={{ physicallyCorrectLights: true }}
        flat={true} // sets renderer.toneMapping = THREE.NoToneMapping
        dpr={[1, 2.5]} // handles resolution of canvas
        shadows={{ enabled: true, type: THREE.PCFShadowMap }}
        resize={{ polyfill: ResizeObserver }}
      >
        {!isMobile && <PanCameraFromCursorControls />}

        {/* <OrbitControls
        makeDefault
        autoRotate={false}
        enableKeys={false}
        enablePan={false}
        enableRotate={false}
        // enableRotate={true}
        enableZoom={true}
        minDistance={0.2}
        maxDistance={1.8}
        target={lookAtTarget}
        enableDamping={true}
        rotateSpeed={isMobile ? 0.7 : 0.35}
        // minAzimuthAngle={0} // horizontal
        // maxAzimuthAngle={0} // horizontal
        minPolarAngle={0} // vertical (TOP)
        maxPolarAngle={2.7} // vertical (BOTTOM)
      /> */}

        <AssetSystem3d>
          <Suspense fallback={null}>
            {/* environment assets, lighting, etc.  */}
            <EnvironmentController />

            {/* Root of configuration experience */}
            {productsState.isPrimed && itemsState.isPrimed && (
              <ExperienceManager
                productsState={productsState}
                // itemsState={itemsState}
              />
            )}
          </Suspense>
        </AssetSystem3d>
      </Canvas>
    </>
  );
}

function ExperienceManager({ productsState }) {
  // whenever scene is revealed,
  useEffect(() => {
    document.addEventListener("SceneIsBeingRevealed", handleSceneReveal);
    return () => {
      document.removeEventListener("SceneIsBeingRevealed", handleSceneReveal);
    };
  }, []);
  function handleSceneReveal() {}

  const { gl, scene, camera } = useThree();

  /**
   *
   * Convert the scene's canvas to a base64 image that can be sent to the client's shopping cart
   *
   */

  useEffect(() => {
    camera.layers.enableAll(); // make sure the main camera is displaying all layers
    document.addEventListener("ScreenshotCanvasForCartImage", convertCanvasToBase64);
    return () => document.removeEventListener("ScreenshotCanvasForCartImage", convertCanvasToBase64);
  }, []);

  const [, setCanvasBase64] = useAtom(canvas_base64);
  const [, setGloveScale] = useAtom(glove_scale);
  const [, setGloveRotation] = useAtom(glove_rotation_override);
  const photoCamera_ref = React.useRef(new THREE.PerspectiveCamera(45, 1, 0.1, 10));

  async function convertCanvasToBase64() {
    let sceneCanvas = gl.domElement;
    let targetCanvas = document.getElementById("screenshot_canvas");

    setGloveRotation([-Math.PI, Math.PI * 1 + Math.random() * 0.001, 0]);
    setGloveScale(1);
    await delay(1500); // wait for rotation to finish

    // render 4 shots to canvas
    for (let takeNumber = 0; takeNumber < 4; takeNumber++) {
      setupScene(takeNumber);
      renderShotToCanvas(takeNumber, sceneCanvas, targetCanvas);
    }

    // save as base64
    let base64 = targetCanvas.toDataURL("image/png");
    setCanvasBase64(base64);
  }

  function setupScene(takeNumber) {
    const cameraPostions = productsState.activeObj.screenshotCameraPostiions;

    photoCamera_ref.current.position.set(...cameraPostions[takeNumber]);

    photoCamera_ref.current.lookAt(0, 0.42, 0);

    photoCamera_ref.current.layers.disable(1); // hides environment
  }

  function renderShotToCanvas(takeNumber, sceneCanvas, targetCanvas) {
    // set drawing position (quadrant) on canvas
    let xPos, yPos;
    if (takeNumber === 0) {
      xPos = 0;
      yPos = 0;
    } else if (takeNumber === 1) {
      xPos = targetCanvas.width / 2;
      yPos = 0;
    } else if (takeNumber === 2) {
      xPos = 0;
      yPos = targetCanvas.height / 2;
    } else if (takeNumber === 3) {
      xPos = targetCanvas.width / 2;
      yPos = targetCanvas.height / 2;
    }

    gl.render(scene, photoCamera_ref.current);

    let ctx = targetCanvas.getContext("2d");

    if (takeNumber === 0) ctx.clearRect(0, 0, targetCanvas.width, targetCanvas.height);

    ctx.drawImage(sceneCanvas, 0, 0, sceneCanvas.width, sceneCanvas.height, xPos, yPos, targetCanvas.width / 2, targetCanvas.height / 2);
  }

  /**
   *
   *
   * Custom Logic For This Experience
   *
   *
   */

  /**
   * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
   */

  return (
    <>
      <ModelController productsState={productsState} />
    </>
  );
}

function ModelController({ productsState }) {
  // need to know when experience is loaded and revealed
  // const [isExperienceLoadedAndRevealed] = useAtom(is_experience_loaded_and_revealed);

  // const [, updateLoadingState] = useAtom(update_loading_count);
  // const getAsset = useAssetLoader();

  // load the model
  // const modelContainer_ref = useRef();
  const gltf = useLoader(useGLTFLoader, productsState?.activeObj?.modelSrc);

  // when gltf is updated
  useEffect(() => {
    addShadowToAllMesh(gltf.scene);
    adjustLightingOnInsideOfGlove(gltf.scene);
    dispatchLoadedEvent();
  }, [gltf]);

  function addShadowToAllMesh(scene) {
    scene.traverse((node) => {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
      }
    });
  }

  function adjustLightingOnInsideOfGlove(scene) {
    scene.traverse((node) => {
      if (node.isMesh && node.material?.name === "inside-material") {
        node.material.envMapIntensity = 0.1;
      }
    });
  }

  // useEffect(() => {
  //   // loadAndInjectModel();
  // }, [applicableItem.modelSrc])

  // async function loadAndInjectModel() {
  //   setModel(null);
  //   modelContainer_ref.current.clear();
  //   updateLoadingState(1);
  //   let newModel = await getAsset(applicableItem.modelSrc);
  //   addShadowToAllMesh(newModel.scene);
  //   setModel(newModel)
  // }

  // useEffect(() => {
  //   if (!model?.scene) return;
  //   modelContainer_ref.current.add(model.scene);
  //   updateLoadingState(-1);
  // }, [model])

  // update TWEEN for tweens to be used in the experience
  // useFrame(() => {
  //   TWEEN.update();
  // })

  return (
    <>
      {/* handle's configuration logic for the glove */}
      <ConfigurationManager gltf={gltf} />

      {/* handle's logic for shopper to interact with the glove */}
      <InteractionManager>
        <primitive object={gltf.scene} />
      </InteractionManager>

      {/* CUSTOM CODE: handle's setting color of stamp-materials */}
      <StampMaterialManager gloveGltf={gltf} />

      {/* CUSTOM CODE: dynamically handle's custom embroideries */}
      {productsState.activeObj.customEmbroideryInfo.map((embroideryInfoObj, index) => {
        if (embroideryInfoObj.isNumberEmbroidery) {
          return <CustomNumberEmbroideryManager key={index} gloveGltf={gltf} embroideryInfoObj={embroideryInfoObj} />;
        } else {
          return <CustomTextEmbroideryManager key={index} gloveGltf={gltf} embroideryInfoObj={embroideryInfoObj} />;
        }
      })}

      {/* CUSTOM CODE: handle's custom image uploads */}
      <CustomImageManager gloveGltf={gltf} customImageAtom={customImageAtom} />
    </>
  );
}

function CustomImageManager({ gloveGltf, customImageAtom }) {
  const [customImageObj] = useAtom(customImageAtom);
  const getAsset = useAssetLoader();

  const targetMaterial = useMemo(() => {
    let mat = findMeshByMaterial("customImage-material", gloveGltf.scene)?.material;
    mat.transparent = true;
    mat.opacity = 0;
    return mat;
  }, [gloveGltf]);

  useEffect(() => {
    if (!customImageObj || !targetMaterial) return;
    updateTexture();
  }, [customImageObj]);

  async function updateTexture() {
    if (!customImageObj.canvas) return;
    let mapTexture = await getAsset("canvas", customImageObj.canvas);

    // apply textures to material
    let oldMap = targetMaterial.map;
    targetMaterial.map = mapTexture;
    targetMaterial.transparent = true;
    targetMaterial.opacity = 1;

    // mark textures and mat for update
    mapTexture.needsUpdate = true;
    targetMaterial.needsUpdate = true;

    if (oldMap) oldMap.dispose();
  }

  return null;
}

function CustomNumberEmbroideryManager({ gloveGltf, embroideryInfoObj }) {
  const [customTextureObj] = useAtom(customEmbroideryAtomsObj[embroideryInfoObj.atomName]);
  const [itemsState] = useAtom(items_state);
  const getAsset = useAssetLoader();

  const targetMaterial = useMemo(() => {
    return findMeshByMaterial(embroideryInfoObj.materialName, gloveGltf.scene)?.material;
  }, [gloveGltf]);

  // find the name of the thumb_mod for this product
  const thumbModName = useMemo(() => {
    let thumbModName;
    Object.keys(itemsState.activeIds).forEach((id) => {
      if (id?.includes("thumb_mod")) thumbModName = id;
    });
    return thumbModName;
  }, [itemsState.activeIds]);

  // define which color component should be used as the embroidery's color
  const applicableColorComponent = useMemo(() => {
    if (embroideryInfoObj.targetColorName != "dynamic") return embroideryInfoObj.targetColorName;
    else {
      if (itemsState.activeIds[thumbModName]._id === "customNumberEmbroidery__thumb_mod") return "embroidery_customNumber_color";
      else return "embroidery_accent_color";
    }
  }, [itemsState.activeIds[thumbModName]._id]);

  const targetColor = useMemo(() => {
    return itemsState.activeObjs[applicableColorComponent]?.material_obj.constructor.color;
  }, [applicableColorComponent, itemsState.activeObjs[applicableColorComponent]._id]);

  useEffect(() => {
    updateTexture();
  }, [customTextureObj]);

  useEffect(() => {
    updateColor();
  }, [targetColor]);

  async function updateTexture() {
    if (!customTextureObj) return;

    let mapTexture = await getAsset("canvas", customTextureObj.canvas);

    // apply textures to material
    let oldMap = targetMaterial.map;
    targetMaterial.map = mapTexture;
    targetMaterial.transparent = true;
    targetMaterial.opacity = 1;

    // set color of material
    targetMaterial.color.set(targetColor);

    // mark textures and mat for update
    mapTexture.needsUpdate = true;
    targetMaterial.needsUpdate = true;

    if (oldMap) oldMap.dispose();
  }

  function updateColor() {
    if (targetMaterial && targetColor) targetMaterial.color.set(targetColor);
  }

  return null;
}

function CustomTextEmbroideryManager({ gloveGltf, embroideryInfoObj }) {
  const [customTextureObj] = useAtom(customEmbroideryAtomsObj[embroideryInfoObj.atomName]);
  const [itemsState] = useAtom(items_state);
  const getAsset = useAssetLoader();

  const targetMaterial = useMemo(() => {
    return findMeshByMaterial(embroideryInfoObj.materialName, gloveGltf.scene)?.material;
  }, [gloveGltf]);

  const targetColor = useMemo(() => {
    return itemsState.activeObjs[embroideryInfoObj.targetColorName]?.material_obj.constructor.color;
  }, [itemsState.activeObjs[embroideryInfoObj.targetColorName]?._id]);

  useEffect(() => {
    updateTexture();
  }, [customTextureObj]);

  useEffect(() => {
    updateColor();
  }, [targetColor]);

  async function updateTexture() {
    if (!customTextureObj) return;

    let mapTexture = await getAsset("canvas", customTextureObj.canvas);

    // apply textures to material
    let oldMap = targetMaterial.map;
    targetMaterial.map = mapTexture;
    targetMaterial.transparent = true;
    targetMaterial.opacity = 1;

    // set color of material
    targetMaterial.color.set(targetColor);

    // mark textures and mat for update
    mapTexture.needsUpdate = true;
    targetMaterial.needsUpdate = true;

    if (oldMap) oldMap.dispose();
  }

  function updateColor() {
    if (targetMaterial && targetColor) targetMaterial.color.set(targetColor);
  }

  return null;
}

function StampMaterialManager({ gloveGltf }) {
  const [productsState] = useAtom(products_state);
  const [componentsState] = useAtom(components_state);
  const [itemsState] = useAtom(items_state);

  /**
   *
   * PALM stamps
   * should be the same on every glove (product)
   *
   */
  const palmStampMaterial = useMemo(() => {
    return findMeshByMaterial("palm-bradleyStamp-material", gloveGltf.scene)?.material;
  }, [productsState.activeId]);

  useEffect(() => {
    if (!itemsState.activeIds.palm_nativeKip_leather._id) return;
    // determine which palm_leather component is active
    const palmLeatherComponents = ["palm_nativeKip_leather", "palm_steerhide_leather", "palm_texas_steerhide_leather"];
    let activeComponentId;
    palmLeatherComponents.forEach((componentId) => {
      const component = componentsState.array.find((componentObj) => componentObj._id === componentId);
      if (!component?.excluded) activeComponentId = componentId;
    });
    const targetItem = itemsState.activeObjs[activeComponentId];
    // set color of palmStampMaterial
    palmStampMaterial?.color.setStyle(targetItem?.stampMaterialColor);
  }, [
    palmStampMaterial,
    itemsState.activeIds.leather_class._id,
    itemsState.activeIds.palm_nativeKip_leather?._id,
    itemsState.activeIds.palm_steerhide_leather?._id,
    itemsState.activeIds.palm_texas_steerhide_leather?._id,
  ]);

  /**
   *
   * static BB stamp on crown back fielders glove
   *
   */
  const staticBBStampMaterial = useMemo(() => {
    return findMeshByMaterial("doubleBStamp-shell-material", gloveGltf.scene)?.material;
  }, [productsState.activeId]);

  useEffect(() => {
    if (!staticBBStampMaterial) return;
    // determine which shellBase component is active
    const shellBaseLeatherComponents = [
      "shellBase_nativeKip_leather__crownFielders",
      "shellBase_steerhide_leather__crownFielders",
      "shellBase_texas_steerhide_leather__crownFielders",
    ];
    let activeComponentId;
    shellBaseLeatherComponents.forEach((componentId) => {
      const component = componentsState.array.find((componentObj) => componentObj._id === componentId);
      if (!component?.excluded) activeComponentId = componentId;
    });
    const targetItem = itemsState.activeObjs[activeComponentId];
    // set color of staticBBStampMaterial
    staticBBStampMaterial?.color.setStyle(targetItem?.stampMaterialColor);
  }, [
    staticBBStampMaterial,
    itemsState.activeIds.leather_class._id,
    itemsState.activeIds.shellBase_nativeKip_leather__crownFielders?._id,
    itemsState.activeIds.shellBase_steerhide_leather__crownFielders?._id,
    itemsState.activeIds.shellBase_texas_steerhide_leather__crownFielders?._id,
  ]);

  /**
   *
   * static BB stamp on open back catchers glove velcro wrist strap
   *
   */
  const catchersBBStampMaterial = useMemo(() => {
    return findMeshByMaterial("doubleBStamp-velcroWrist-material", gloveGltf.scene)?.material;
  }, [productsState.activeId]);

  useEffect(() => {
    if (!catchersBBStampMaterial) return;
    // determine which wristStrap component is active
    const wristStrapLeatherComponents = ["wristStrap_nativeKip_leather", "wristStrap_steerhide_leather", "wristStrap_texas_steerhide_leather"];
    let activeComponentId;
    wristStrapLeatherComponents.forEach((componentId) => {
      const component = componentsState.array.find((componentObj) => componentObj._id === componentId);
      if (!component?.excluded) activeComponentId = componentId;
    });
    const targetItem = itemsState.activeObjs[activeComponentId];
    // set color of catchersBBStampMaterial
    catchersBBStampMaterial?.color.setStyle(targetItem?.stampMaterialColor);
  }, [
    catchersBBStampMaterial,
    itemsState.activeIds.leather_class._id,
    itemsState.activeIds.wristStrap_nativeKip_leather?._id,
    itemsState.activeIds.wristStrap_steerhide_leather?._id,
    itemsState.activeIds.wristStrap_texas_steerhide_leather?._id,
  ]);

  /**
   *
   * DYNAMIC stamp controller
   * uses data on items to dynamically apply correct material color to stamp
   *
   */

  // iterate through itemsState.activeObjs to collect all stamp data
  const stampData = useMemo(() => {
    const stampData = [];
    Object.entries(itemsState.activeObjs).forEach(([key, value]) => {
      if (value?.stampData) stampData.push(value.stampData);
    });
    return stampData;
  }, [productsState.activeId, itemsState.activeObjs]);

  // iterate through stampData and update the material colors
  useEffect(() => {
    stampData.forEach((stampObj) => {
      // find targetMaterial that we'll update the color of
      const targetMaterial = findMeshByMaterial(stampObj.materialName, gloveGltf.scene)?.material;

      // find targetItem that we'll get color data from
      let activeComponent;
      stampObj.componentTargetNames?.forEach((componentName) => {
        const component = componentsState.array.find((componentObj) => componentObj._id === componentName);
        if (component && !component.excluded) {
          activeComponent = component;
        }
      });
      if (!activeComponent) return;

      const targetItem = itemsState.activeObjs[activeComponent._id];

      // set color of targetMaterial
      targetMaterial?.color.setStyle(targetItem?.stampMaterialColor);
    });
  }, [stampData, itemsState.activeObjs, itemsState.activeIds.leather_class._id]);

  return null;
}

function InteractionManager({ children }) {
  const [componentsState] = useAtom(components_state);
  const [itemsState] = useAtom(items_state);

  const rotationParent_ref = useRef();
  const rotationRoot_ref = useRef();
  useEffect(() => {
    rotationRoot_ref.current = rotationParent_ref.current.children[0];
  }, []);

  /**
   * handles rotating the glove to focus on newly chosen item
   */
  const [targetRotation, setTargetRotation] = useState([-Math.PI, Math.PI * 1.3, 0]);
  const [rotationOverride] = useAtom(glove_rotation_override);
  useEffect(() => {
    if (rotationOverride) setTargetRotation(rotationOverride);
  }, [rotationOverride]);
  useEffect(() => {
    if (componentsState.activeObj?.characteristics?.includes("focus-component")) {
      setTargetRotation(() => {
        let targetRotationArray = [...componentsState.activeObj.focusData.rotation];

        targetRotationArray = targetRotationArray.map((targetRadians, index) => {
          let translator = ["x", "y", "z"];
          let exactRotation = rotationRoot_ref.current.rotation[translator[index]];
          let exactTurns = exactRotation / (Math.PI * 2);
          let fullTurns = Math.round(exactTurns);

          let rotationsToTest = [
            (fullTurns - 1) * (Math.PI * 2) + targetRadians,
            fullTurns * (Math.PI * 2) + targetRadians,
            (fullTurns + 1) * (Math.PI * 2) + targetRadians,
          ];
          // console.log('rotationsToTest', rotationsToTest)

          let rotationDeltas = rotationsToTest.map((rotation) => {
            return Math.abs(exactRotation - rotation);
          });
          // console.log('rotationDeltas ', rotationDeltas)

          let closestRotation;
          let smallestDelta = 100;
          rotationDeltas.forEach((rotationDelta, index) => {
            if (rotationDelta < smallestDelta) {
              smallestDelta = rotationDelta;
              closestRotation = rotationsToTest[index];
            }
          });

          return closestRotation;
        });

        // need to barely increment value so state change registers
        return [targetRotationArray[0] + Math.random() * 0.001, targetRotationArray[1] + Math.random() * 0.001, targetRotationArray[2] + Math.random() * 0.001];
      });
    }
  }, [itemsState.activeIds]);

  /**
   *
   *
   * handles scaling the glove when user "zooms"
   *
   *
   */

  const camera = useThree(({ camera }) => camera);
  const gl = useThree(({ gl }) => gl);

  const controls_ref = useRef();

  const [gloveScale, setGloveScale] = useAtom(glove_scale);

  useEffect(() => {
    setupScrollToScaleControls();
  }, []);
  function setupScrollToScaleControls() {
    controls_ref.current = new ScrollToScaleControls(camera, gl.domElement, setGloveScale);
    controls_ref.current.target.set(0, 0.4, 0); // lookAt is mainly handled by PanCameraFromCursorControls
    controls_ref.current.enableRotate = false;
    controls_ref.current.enablePan = false;
    controls_ref.current.update();
  }
  // useFrame(() => {
  // controls_ref.current.update();
  // })

  return (
    <group>
      {/* needs this group with weird scale and rotation to make the presentation controls work properly */}
      <group ref={rotationParent_ref} position={[0, 0.4, 0]} rotation={[0, -0.5, 0]} scale={[-gloveScale, -gloveScale, gloveScale]}>
        <PresentationControls
          global={true} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on dragx
          snap={false} // Snap-back to center (can also be a spring config)
          speed={isMobile ? 2 : 1.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={targetRotation} // Default rotation
          polar={[-Infinity, Infinity]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 150, friction: 50 }} // Spring config
        >
          <Float
            floatIntensity={0.1} // Up/down float intensity, works like a multiplier with floatingRange, defaults to 1
          >
            {/* the model */}
            {children}
          </Float>
        </PresentationControls>
      </group>
    </group>
  );
}

function ConfigurationManager({ gltf }) {
  const [componentsState] = useAtom(components_state);
  const [itemsState] = useAtom(items_state);

  return (
    <>
      {/* iterate through all the active items */}
      {itemsState.activeIds &&
        Object.entries(itemsState.activeIds).map(([componentId]) => {
          // find respective component obj
          let componentObj = componentsState.array.find((component) => component._id === componentId);

          if (componentObj?.excluded) return null;

          // find respective item obj
          let itemObj = itemsState.activeObjs[componentId];

          if (!itemObj || (itemObj && Object.keys(itemObj).length === 0)) {
            console.log("EMPTY ITEM OBJ", componentId, itemObj);
            AlertSlackOfError("ConfigurationManager", `EMPTY ITEM OBJ: ${componentId}`);
          }
          // check for 'material' or 'mesh' component so we know which controllers to instantiate
          if (componentObj?.characteristics?.includes("material-component"))
            return <MaterialController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
          else if (componentObj?.characteristics?.includes("mesh-component"))
            return <MeshController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
        })}
    </>
  );
}

function MeshController({ item, component, modelScene }) {
  const targetNode = useMemo(() => {
    return modelScene.getObjectByName(component.nodeTargetName);
  }, []);

  useEffect(() => {
    if (!targetNode) return;

    targetNode.traverse((node) => {
      // criteria to be visible: itemGroups, item children, item node that is active
      if (node.name?.includes("itemGroup") || !node.name?.includes("item") || node.name === item.nodeTargetName) {
        node.visible = true;
      }

      // criteria to be hidden: inactive 'item' nodes
      else {
        node.visible = false;
      }
    });
  }, [targetNode, item]);

  return null;
}

function MaterialController({ item, component, modelScene }) {
  // const [, updateLoadingState] = useAtom(update_loading_count);

  const [lacingMap, setLacingMap] = useAtom(lacing_map); // CUSTOM CODE

  // const applicableItem = useActiveItem(itemContainerId);

  const targetMaterial = useMemo(() => {
    return findMeshByMaterial(component.materialTargetName, modelScene)?.material;
  }, []);

  // handles updating the material
  useEffect(() => {
    if (!targetMaterial || !item.material_obj) return;

    let newMat = createMaterial(item.material_obj);

    handleMaterialTextures(targetMaterial, newMat);

    applyMaterialProperties(null, item.material_obj.properties, newMat);

    if (targetMaterial.name === "lacing-material") handleLacingMaterial(targetMaterial, newMat, item, lacingMap, setLacingMap); // CUSTOM CODE

    let matName = targetMaterial.name;

    targetMaterial.copy(newMat);

    targetMaterial.name = matName;
    targetMaterial.side = THREE.DoubleSide;
    targetMaterial.needsUpdate = true;

    newMat.dispose();
  }, [targetMaterial, item]);

  return null;
}

function createMaterial(materialObj) {
  switch (materialObj.type) {
    case "MeshStandardMaterial":
      return new THREE.MeshStandardMaterial(materialObj.constructor);
      break;

    case "MeshBasicMaterial":
      return new THREE.MeshBasicMaterial(materialObj.constructor);
      break;

    default:
      return new THREE.MeshStandardMaterial(materialObj.constructor);
      break;
  }
}

// needed because we don't define any textures in our data but want to carry them over from the oldMat to newMat
function handleMaterialTextures(oldMaterial, newMaterial) {
  if (oldMaterial.map) newMaterial.map = oldMaterial.map;
  if (oldMaterial.transparent) newMaterial.transparent = true;
  if (oldMaterial.normalMap) {
    newMaterial.normalMap = oldMaterial.normalMap;
    newMaterial.normalScale = oldMaterial.normalScale;
  }
}

// CUSTOM CODE
// needed because the white lace doesn't need the map texture all the other laces need
function handleLacingMaterial(oldLacingMaterial, newLacingMaterial, item, lacingMap, setLacingMap) {
  if (!lacingMap) setLacingMap(oldLacingMaterial.map);
  if (item._id === "white__lacing_color") newLacingMaterial.map = null;
  else if (lacingMap) newLacingMaterial.map = lacingMap;
}

// function OLDMaterialController({itemContainerId, materialName, productModelScene}) {

//   const [, updateLoadingState] = useAtom(update_loading_count);

//   const applicableItem = useActiveItem(itemContainerId);

//   // update material
//   useEffect(() => {
//     updateMaterial();
//   }, [applicableItem, materialName])

//   async function updateMaterial() {
//     let activeMat = getActiveMaterial();
//     updateLoadingState(1);
//     let newMat = await createNewMaterial(applicableItem.material_obj);
//     activeMat.copy(newMat);
//     activeMat.needsUpdate = true;
//     newMat.dispose();
//     // ----------------
//     // custom code
//     if (materialName === 'desk-material-production') {
//       // makes sure storage_drawers use the same material as the desk
//       let storageDrawersMat = findMeshByMaterial('storage-drawers-variable', productModelScene)?.material;
//       if (storageDrawersMat) {
//         storageDrawersMat.copy(activeMat);
//         storageDrawersMat.name = 'storage-drawers-variable';
//         storageDrawersMat.needsUpdate = true;
//       }
//       // scales carbon fiber texture to be more accurate
//       if (applicableItem._id === 'carbon_fiber') {
//         activeMat.map.repeat.setScalar(1.5);
//         activeMat.map.needsUpdate = true;
//       }
//     }
//     // ----------------
//     updateLoadingState(-1);
//     dispatchLoadedEvent();
//   }

//   function getActiveMaterial() {
//     let mat;
//     productModelScene.traverse((node) => {
//       if (node.isMesh && node.material.name === materialName)
//         mat = node.material;
//     })
//     return mat;
//   }

//   const getAsset = useAssetLoader();
//   async function createNewMaterial(material_obj) {
//     let newMat;
//     if (material_obj.type === 'MeshStandardMaterial')
//       newMat = new THREE.MeshStandardMaterial(material_obj.constructor)
//     else if (material_obj.type === 'MeshPhysicalMaterial')
//       newMat = new THREE.MeshPhysicalMaterial(material_obj.constructor);
//     else if (material_obj.type === 'MeshBasicMaterial')
//       newMat = new THREE.MeshBasicMaterial(material_obj.constructor);

//     newMat.name = materialName;

//     newMat = await applyMaterialProperties(getAsset, material_obj.properties, newMat);

//     return newMat;
//   }

//   return null;

// }

// function AnimationController({animationClips, animationName, meshToAnimate}) {

//   // setup Mixer
//   const mixer = useMemo(() => {
//     return new THREE.AnimationMixer(meshToAnimate);
//   }, [meshToAnimate])
//   useFrame((state, dt) => mixer && mixer.update(dt))

//   // setup Action
//   const action = useMemo(() => {
//     let a;
//     animationClips.forEach((clip) => {
//       if (clip.name === animationName)
//         a = mixer.clipAction(clip);
//     })
//     return a;
//   }, [animationClips, animationName, meshToAnimate])

//   // animation controls

//   function playAnimation(action, secDuration) {
//     if (!action) return;
//     action.clampWhenFinished = true;
//     action.setLoop(THREE.LoopOnce);
//     if (!secDuration) action.setEffectiveTimeScale(1);
//     else action.setDuration(secDuration);
//     action.paused = false;
//     action.play();
//   }

//   function pauseAnimation(action) {
//     if (!action) return;
//     action.paused = true;
//   }

//   function setTime(action, newTime) {
//     if (!action) return;
//     action.time = newTime;
//     action.paused = true;
//     action.play();
//   }

//   return null;

// }

/**
 *
 * helper functions
 *
 */

// function applyModsToObject3D(object3D, mods, specificModelScene, rootModelScene) {
//   // iterate through mods & apply mod to the object3D
//   Object.entries(mods).forEach(([key, value]) => {
//     // SPECIAL CASE: copyMaterial means we need to update material via copy
//     if (key === 'copyMaterial') {
//       let matToUpdate = findMeshByMaterial(value.to, specificModelScene).material;
//       let matNameToKeep = `${matToUpdate.name}`;
//       let matToCopy = findMeshByMaterial(value.from, rootModelScene).material;
//       matToUpdate.copy(matToCopy);
//       matToUpdate.name = matNameToKeep;
//     }
//     // SPECIAL CASE: replaceMaterial means we need to update a mesh to use a different material
//     if (key === 'replaceMaterial') {
//       let sourceMat = findMeshByMaterial(value.keeper, rootModelScene).material;
//       let meshToReplace = findMeshByMaterial(value.replace, specificModelScene);
//       if (meshToReplace) meshToReplace.material = sourceMat;
//     }
//     // CASE: arrays turn into vectors
//     else if (Array.isArray(value)) {
//       object3D[key].fromArray(value);
//     }
//     // pass in new value as is
//     else {
//       object3D[key] = value;
//     }
//   });
// }

async function applyMaterialProperties(getAsset, material_props, material) {
  let texturePromises = [];

  Object.entries(material_props).forEach(([key, value]) => {
    // textures
    if (key.toLowerCase()?.includes("map")) {
      let texturePromise = new Promise(async (resolve) => {
        let texture = await getAsset(value);
        material[key] = texture;
        if (key?.includes("metal")) material["roughnessMap"] = texture;
        else if (key?.includes("rough")) material["metalnessMap"] = texture;
        resolve();
      });
      texturePromises.push(texturePromise);
    }

    // properties that need some preperation

    // arrays turn into vectors
    else if (Array.isArray(value)) {
      material[key].fromArray(value);
    }

    // regular material properties
    else {
      material[key] = value;
    }
  });

  await Promise.all(texturePromises);

  material.needsUpdate = true;
  return material;
}

function findMeshByMaterial(materialName, scene) {
  let mesh;
  scene.traverse((node) => {
    if (node.isMesh && node.material.name == materialName) mesh = node;
  });
  return mesh;
}

function dispatchLoadedEvent() {
  document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
}

function delay(milliseconds) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, milliseconds);
  });
}
