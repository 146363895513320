import PropTypes from "prop-types";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const RectangleButton = ({ type = "submit", label, iconSrc, onClickHandler = null, gaObj }) => {
  const handleClick = (e) => {
    if (onClickHandler) onClickHandler(e);

    pushGTMAnalyticsEvent(gaObj);
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      onTouchStart={(e) => {
        e.preventDefault();
        handleClick(e);
      }}
      className="p-2 rectangleBtn"
    >
      {label}
      {iconSrc && <img alt="Button Icon" src={iconSrc} />}
    </button>
  );
};

RectangleButton.propTypes = {
  label: PropTypes.string,
  iconSrc: PropTypes.string,
  onClickHandler: PropTypes.func,
};
