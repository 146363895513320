import * as THREE from "three";
import { useRef, useEffect, useMemo } from "react";
import { useFrame, useThree } from "@react-three/fiber";

export default function PanCameraFromCursorControls() {
  const camera = useThree(({ camera }) => camera);
  const sceneEl = useMemo(() => {
    return document.getElementById("builder-scene-canvas-container");
  }, []);
  const isActive_ref = useRef(false);

  const startPosVec_ref = useRef(camera.position.clone());
  const targetPosVec_ref = useRef(startPosVec_ref.current.clone());
  const lookAtPos_ref = useRef(new THREE.Vector3(0, 0.4, 0));
  const speed_ref = useRef(0.42);
  const variance_x = 0.25;
  const variance_y = 0.25;
  const lowerBoundPos_x = useMemo(() => {
    return startPosVec_ref.current.x - variance_x / 2;
  }, []);
  const lowerBoundPos_y = useMemo(() => {
    return startPosVec_ref.current.y - variance_y / 2;
  }, []);

  // init()
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("SceneIsBeingRevealed", () => {
      isActive_ref.current = true;
    });
    sceneEl.addEventListener("mousemove", updateTargetPosition);
    sceneEl.addEventListener("mouseleave", resetTargetPosition);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      sceneEl.removeEventListener("mouseleave", resetTargetPosition);
      sceneEl.removeEventListener("mousemove", updateTargetPosition);
    };
  }, []);

  // reset camera position when tab is reactivated (fixes camera flying off for unkown reason)
  function handleVisibilityChange() {
    if (document.visibilityState === "visible") {
      resetTargetPosition();
      camera.position.copy(startPosVec_ref.current);
    }
  }

  useFrame((_state, delta) => {
    if (document.visibilityState !== "visible") return;
    if (isActive_ref.current && camera.position.distanceTo(targetPosVec_ref.current) > 0.01) {
      camera.position.lerp(targetPosVec_ref.current, speed_ref.current * delta);
    }
    // must be called after lerp or jerkyness insues
    camera.lookAt(lookAtPos_ref.current);
  });

  function updateTargetPosition(event) {
    if (!isActive_ref.current) return;

    let normalizedMousePos_x = 1 - event.offsetX / sceneEl.clientWidth;
    let normalizedMousePos_y = 1 - event.offsetY / sceneEl.clientHeight;

    if (Number.isNaN(normalizedMousePos_x) || Number.isNaN(normalizedMousePos_y)) return;

    // set the new target position
    targetPosVec_ref.current.set(lowerBoundPos_x + variance_x * normalizedMousePos_x, lowerBoundPos_y + variance_y * normalizedMousePos_y, camera.position.z);
  }

  function resetTargetPosition() {
    targetPosVec_ref.current.copy(startPosVec_ref.current);
  }

  return null;
}
