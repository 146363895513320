import React from "react";
import { createRoot } from "react-dom/client";
import EmbedController from "../embed/EmbedController";
import ProductBuilder from "./components/ProductBuilder";
import { UrlDataController } from "./components/dataManagers/UrlDataController";
import { HandleGeneralErrors } from "./components/errorHandling/handleErrors.js";

import "../globals.scss";

const container = document.getElementById("react-root");
const root = createRoot(container);

function beginExperience() {
  if (EmbedController.isEmbedded) {
    document.addEventListener("InitEmbed", reactRender);
  } else if (window.location.href?.includes("/live")) {
    window.location = "https://www.bradleybaseballgloves.com/products/custom-first-base-model-3d-configurator";
  } else if (window.location.href?.includes("/dev")) {
    window.location = "https://www.bradleybaseballgloves.com/products/custom-first-base-model-3d-configurator-beta";
  } else {
    reactRender();
  }
}

function reactRender() {
  root.render(
    <React.StrictMode>
      <HandleGeneralErrors>
        <UrlDataController>
          <ProductBuilder />
        </UrlDataController>
      </HandleGeneralErrors>
    </React.StrictMode>
  );
}

beginExperience();
