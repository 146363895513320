import React from "react";
import { ItemsSection } from "../UI/ItemsSection/ItemsSection";
import { Accordion } from "../UI/Accordion/Accordion";
import { NestedAccordion } from "../UI/Accordion/NestedAccordion";
import { InputBox } from "../UI/InputBox/InputBox";
import { FontSection } from "../UI/FontSection/FontSection";
import { FileDragDrop } from "../UI/FileDragDrop/FileDragDrop";
import { items_state } from "../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";

const Components = {
  ItemsSection,
  Accordion,
  NestedAccordion,
  InputBox,
  FontSection,
  FileDragDrop,
};

export const CreateUIComponent = ({ gloveComponent, reactSchema, componentItems, ...props }) => {
  const [itemsState] = useAtom(items_state);

  if (typeof Components[reactSchema.component] !== "undefined") {
    return React.createElement(
      Components[reactSchema.component],
      {
        gloveComponent,
        componentItems,
        ...props,
      },
      reactSchema?.children &&
        (typeof reactSchema.children === "string"
          ? reactSchema.children
          : reactSchema?.children?.map(
              (c, index) =>
                // check if child has dependency on items
                (itemsState.activeIds[gloveComponent._id]._id === c.dependency || !c.dependency) && (
                  <CreateUIComponent key={index} gloveComponent={gloveComponent} reactSchema={c} componentItems={componentItems} {...c.props} />
                )
            ))
    );
  }

  return React.createElement(() => <div>{reactSchema.component} hasn't been made</div>, { key: Math.floor(Math.random() * 100) });
};
